import { useLocation } from '@reach/router';
import React from 'react';

import useAuth from '../../../hooks/useAuth';
import useViewport from '../../../hooks/useViewport';
import { Modal } from '../../shared/modal/Modal';
import AccountTile from '../account-menu-tile/AccountMenuTile';
import styles from './AccountNavMenu.module.scss';
import MobileFiltersBtn from '../../../components/slab/mobile-filters/mobile-filters-btn/MobileFiltersBtn';

interface MenuTile {
  title: string;
  path?: string;
  onClick?: () => void;
}

const AccountMenu: React.FC = () => {
  const { isTablet } = useViewport();

  const [isNavModalOpen, setNavModalState] = React.useState(false);
  const { logout } = useAuth();

  const location = useLocation();
  const pathLength = location.pathname.split('/');

  const checkingFunc = (path: string | undefined) => {
    return pathLength.length === 4
      ? `${path}/${pathLength[3]}` === location?.pathname
      : path === location?.pathname;
  };

  const tiles: MenuTile[] = [
    { title: 'Favorite Lists', path: '/account/favorites' },
    { title: 'Account Details', path: '/account' },
    { title: '', path: '' },
    { title: 'Log Out', onClick: logout },
  ];

  const toggleNavModal = () => {
    setNavModalState(!isNavModalOpen);
  };

  return (
    <div className={styles.container}>
      {isTablet ? (
        <>
          <MobileFiltersBtn name={'FILTER'} onClick={toggleNavModal} />
          <Modal isOpen={isNavModalOpen} handleClose={toggleNavModal}>
            <div className={styles.modal}>
              <span className={styles.accounts}>ACCOUNTS</span>
              <div className={styles.divider} />
              {tiles.map((p, i) => (
                <div key={'tile_' + i}>
                  <AccountTile
                    title={p.title}
                    path={p.path}
                    isSelected={checkingFunc(p.path)}
                    onClick={p.onClick}
                  />
                  <div className={styles.divider} />
                </div>
              ))}
            </div>
          </Modal>
        </>
      ) : (
        <>
          <div className={styles.divider} />
          {tiles.map((p, i) => (
            <AccountTile
              title={p.title}
              path={p.path}
              isSelected={checkingFunc(p.path)}
              onClick={p.onClick}
              key={'tile_' + i}
            />
          ))}
          <div className={styles.divider} />
        </>
      )}
    </div>
  );
};

export default AccountMenu;
