import { RouteComponentProps } from '@reach/router';
import React from 'react';
import AccountLayout from '../../components/layout/account-layout';
import AccountFavoritesList from './account-favorites/AccountFavorites';
import { FormattedUserFavourite } from '../../templates/account/interfaces';

interface Props {
  userFavourites: FormattedUserFavourite[];
}

const AccountFavorites: React.FC<RouteComponentProps<Props>> = ({
  userFavourites,
}: RouteComponentProps<Props>) => {
  if (typeof window === 'undefined') {
    return <div></div>;
  }

  return (
    <AccountLayout>
      <AccountFavoritesList userFavourites={userFavourites!} />
    </AccountLayout>
  );
};

export default AccountFavorites;
