import React from 'react';
import styles from './ChangeForm.module.scss';
import { Formik, Field, Form } from 'formik';
import classnames from 'classnames';
import FieldError from '../../shared/forms/field-error/FieldError';

export interface ChangeFormValues {
  currentValue: string;
  newValue: string;
  confirmNewValue: string;
}

export enum FormType {
  EMAIL = 'email',
  PASSWORD = 'password',
}

interface Props {
  onSubmit: (values: ChangeFormValues) => Promise<boolean>;
  validate: (value: string) => string | undefined;
  formType: FormType;
  currentValue?: string;
  name: string;
}

const ChangeForm: React.FC<Props> = ({
  onSubmit,
  formType,
  currentValue,
  name,
  validate,
}: Props) => {
  const withFormName = (fieldName: string) => `${fieldName}_${name}`;

  return (
    <div className={styles.container}>
      <h1 className={styles.title}>CHANGE {formType}</h1>
      <Formik
        initialValues={{
          currentValue: currentValue ?? '',
          newValue: '',
          confirmNewValue: '',
        }}
        onSubmit={async (values, { resetForm }) => {
          const success = await onSubmit(values);
          if (success) {
            resetForm();
          }
        }}
      >
        {({ values, touched, errors }) => (
          <Form className={styles.formContainer}>
            <div className={styles.formItem}>
              <label className={styles.formItemLabel} htmlFor={withFormName('currentValue')}>
                Current {formType}*
              </label>
              {currentValue ? (
                <span className={styles.name}>{currentValue}</span>
              ) : (
                <Field
                  className={styles.formItemField}
                  id={withFormName('currentValue')}
                  name="currentValue"
                  type={formType}
                />
              )}
            </div>

            <div
              className={classnames(
                styles.formItem,
                touched.newValue && errors.newValue && styles.error,
              )}
            >
              <label className={styles.formItemLabel} htmlFor={withFormName('newValue')}>
                New {formType}*
              </label>
              <div className={styles.withTextError}>
                <Field
                  className={styles.formItemField}
                  id={withFormName('newValue')}
                  name="newValue"
                  type={formType}
                  validate={validate}
                />
                <FieldError name="newValue" />
              </div>
            </div>

            <div
              className={classnames(
                styles.formItem,
                touched.confirmNewValue && errors.confirmNewValue && styles.error,
              )}
            >
              <label className={styles.formItemLabel} htmlFor={withFormName('confirmNewValue')}>
                Confirm new {formType}*
              </label>
              <div className={styles.withTextError}>
                <Field
                  className={styles.formItemField}
                  id={withFormName('confirmNewValue')}
                  name="confirmNewValue"
                  type={formType}
                  validate={() => {
                    if (values.confirmNewValue !== values.newValue) {
                      return `Values do not match`;
                    }
                  }}
                />
                <FieldError name="confirmNewValue" />
              </div>
            </div>
            <button type="submit" className={styles.buttonContainer}>
              SAVE CHANGES
            </button>
          </Form>
        )}
      </Formik>
      <div className={styles.divider} />
    </div>
  );
};

export default ChangeForm;
