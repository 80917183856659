import React from 'react';
import styles from './AccountMenuTile.module.scss';
import { Link } from 'gatsby';

interface Props {
  title: string;
  isSelected?: boolean;
  path?: string;
  onClick?: () => void;
}

const AccountTile: React.FC<Props> = ({ title, isSelected, path, onClick }: Props) => {
  return isSelected ? (
    <div className={styles.selectedContainer}>
      <span className={styles.selectedContent}>{title}</span>
      <div className={styles.circle} />
    </div>
  ) : onClick ? (
    <a className={styles.container} onClick={onClick}>
      <span className={styles.content}>{title}</span>
    </a>
  ) : (
    <Link to={path ?? ''} className={styles.container}>
      <span className={styles.content}>{title}</span>
    </Link>
  );
};

export default AccountTile;
