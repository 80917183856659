import cn from 'classnames';
import React, { PropsWithChildren } from 'react';

import useAuth from '../../hooks/useAuth';
import AccountMenu from '../account/account-nav-menu/AccountNavMenu';
import Layout from '../layout/layout';
import SEO from '../seo';
import styles from './account-layout.module.scss';

type Props = PropsWithChildren<unknown>;

const AccountLayout: React.FC<Props> = ({ children }: Props) => {
  const {
    state: { user },
  } = useAuth();

  return (
    <Layout isWhiteBackground={true}>
      <SEO title="Account" />
      <section className={styles.wrapper}>
        <section className={styles.navBar}>
          <div className={cn(styles.headerContainer, 'hide-on-tablet')}>
            <p className={cn(styles.title, styles.header)}>Hi, {user?.firstName}.</p>
            <span className={styles.header}>Welcome!</span>
          </div>

          <AccountMenu />
        </section>
        <section className={styles.content}>
          <div className={cn(styles.headerContainer, 'hide-on-desktop')}>
            <p className={cn(styles.title, styles.header)}>Hi, {user?.firstName}.</p>
            <span className={styles.header}>Welcome!</span>
          </div>

          {children}
        </section>
      </section>
    </Layout>
  );
};

export default AccountLayout;
