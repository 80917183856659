import React from 'react';
import styles from './AccountFavorites.module.scss';
import FavoriteList from '../favorite-list/FavoriteList';
import { FavoriteTypes } from '../../shared/enum/favorite-data-type';
import { Slab, Tile } from '../../shared/interfaces';
import { Album, Project } from '../../portfolio/interfaces';
import { FormattedUserFavourite } from '../../../templates/account/interfaces';

interface Props {
  userFavourites: FormattedUserFavourite[];
}

const AccountFavoritesList: React.FC<Props> = ({ userFavourites }: Props) => {
  return (
    <div className={styles.AccountFavoritesList}>
      <h1 className={styles.mobileTitle}>FAVORITE LIST</h1>
      <div className={styles.tilesContainer}>
        {userFavourites.map((f, i) => (
          <FavoriteList userFavourite={f} index={i} key={f.name} />
        ))}
      </div>
    </div>
  );
};

export interface CollectionInfo {
  [FavoriteTypes.TILE]: { [key: number]: Tile };
  [FavoriteTypes.SLAB]: { [key: number]: Slab };
  [FavoriteTypes.ALBUM]: { [key: number]: Album };
  [FavoriteTypes.PROJECT]: { [key: number]: Project };
}

export interface GenericCollection {
  id: number;
  type: FavoriteTypes;
  name: string;
  previewUrl: string;
  path: string;
  entity: Tile | Slab | Project | Album;
}

export default AccountFavoritesList;
