import React from 'react';
import styles from './AccountDetails.module.scss';
import ChangeForm, { FormType, ChangeFormValues } from '../change-form/ChangeForm';
import iconEdit from '../../../images/iconEdit.svg';
import { Formik, Form, Field } from 'formik';
import useAuth from '../../../hooks/useAuth';
import FieldError from '../../shared/forms/field-error/FieldError';
import classnames from 'classnames';
import { NotificationManager } from 'react-notifications';
import { validatePassword, validateEmail } from '../../shared/forms/validators';

const AccountDetails: React.FC = () => {
  const {
    state: { user },
    update,
  } = useAuth();

  const saveName = async ({ username }: { username: string }) => {
    const parts = username?.split(/\s/)?.filter((p) => p);
    try {
      await update({ firstName: parts[0], lastName: parts.slice(1)?.join(' ') ?? '' });
    } catch (e) {
      NotificationManager.warning(e);
    }
  };

  const updatePassword = async ({
    currentValue: currentPassword,
    newValue: newPassword,
  }: ChangeFormValues) => {
    try {
      await update({ newPassword, password: currentPassword });
      NotificationManager.success('Password successfully updated');
      return true;
    } catch (e) {
      NotificationManager.warning(e);
      return false;
    }
  };

  const updateEmail = async ({ newValue: email }: ChangeFormValues) => {
    try {
      await update({ email });
      NotificationManager.success('Email successfully updated');
      return true;
    } catch (e) {
      NotificationManager.warning(e);
      return false;
    }
  };

  return (
    <div className={styles.AccountDetails}>
      <Formik
        initialValues={{
          username: user?.firstName + ' ' + user?.lastName,
        }}
        onSubmit={saveName}
      >
        {({ submitForm, touched, errors }) => (
          <Form className={styles.nameFormContainer}>
            <h1 className={styles.mobileTitle}>ACCOUNT DETAIL</h1>
            <div className={styles.formRow}>
              <label className={styles.nameFormLabel} htmlFor="profileUserName">
                Name
              </label>
              <div className={styles.inputBox}>
                <div
                  className={classnames(
                    styles.withTextError,
                    touched.username && errors.username && styles.error,
                  )}
                >
                  <Field
                    id="profileUserName"
                    className={styles.nameFormField}
                    name="username"
                    onBlur={submitForm}
                    validate={(value: string) => {
                      if (!value) {
                        return 'Name cannot be empty';
                      }
                    }}
                  />
                  <FieldError name="username" />
                </div>

                <img
                  className={styles.editIcon}
                  src={iconEdit}
                  alt={'edit name'}
                  onClick={() => {
                    const nameInput = document.getElementById('profileUserName');
                    nameInput?.focus();
                  }}
                />
              </div>
            </div>
            <div className={styles.divider} />
          </Form>
        )}
      </Formik>

      <div className={styles.formContainer}>
        <ChangeForm
          name="password"
          formType={FormType.PASSWORD}
          currentValue=""
          onSubmit={updatePassword}
          validate={validatePassword}
        />
      </div>
      <div className={styles.formContainer}>
        <ChangeForm
          name="email"
          formType={FormType.EMAIL}
          currentValue={user?.email}
          onSubmit={updateEmail}
          validate={validateEmail}
        />
      </div>
    </div>
  );
};

export default AccountDetails;
