import React from 'react';
import styles from './FavoriteList.module.scss';
import { Link } from 'gatsby';
import { FormattedUserFavourite } from '../../../templates/account/interfaces';
import shareIcon from '../../../images/icon-share.svg';
import { Modal } from '../../shared/modal/Modal';
import { ShareModal } from '../../shared/modal/share-modal/ShareModal';
import ArrowButton from '../../shared/arrow-button/ArrowButton';

interface Props {
  index: number;
  userFavourite: FormattedUserFavourite;
}

const FavoriteList: React.FC<Props> = ({ userFavourite, index }: Props) => {
  const [c1, c2, c3, c4] = userFavourite.collections;

  const [isShareModalOpen, setShareModalState] = React.useState(false);

  //const LINK = `${location?.origin}/account/favorites/${userFavourite.id}`;
  const LINK = `${location?.origin}/favorite-list-share?id=${userFavourite.id}`;

  const toggleShareModal = () => {
    setShareModalState(!isShareModalOpen);
  };

  return (
    <div className={styles.container}>
      <div className={styles.delimiterContainer}>
        <span className={styles.index}>{0 + (index + 1).toString()}</span>
        <div className={styles.delimiter}></div>
      </div>
      <div className={styles.titleContainer}>
        <h1 className={styles.title}>{userFavourite.name}</h1>
        <button className={styles.shareButton} onClick={toggleShareModal}>
          <img
            className={styles.editIcon}
            src={shareIcon}
            alt={'edit name'}
            style={{ marginRight: '1rem' }}
          />
          {'  Share'}
        </button>
      </div>
      <Link to={`/account/favorites/${userFavourite.id}`}>
        <div className={styles.cards}>
          <div key={index}>
            <div className={styles.imgRow}>
              {c1 ? (
                <img src={c1.previewUrl} className={styles.img} alt="favorite img" />
              ) : (
                <div className={styles.imgPlaceholder} />
              )}

              {c2 ? (
                <img src={c2.previewUrl} className={styles.img} alt="favorite img" />
              ) : (
                <div className={styles.imgPlaceholder} />
              )}
            </div>
            <div className={styles.imgRow}>
              {c3 ? (
                <img src={c3.previewUrl} className={styles.img} alt="favorite img" />
              ) : (
                <div className={styles.imgPlaceholder} />
              )}

              {c4 ? (
                <img src={c4.previewUrl} className={styles.img} alt="favorite img" />
              ) : (
                <div className={styles.imgPlaceholder} />
              )}
            </div>
          </div>
        </div>
      </Link>
      <div className={styles.buttonWrapper}>
        <Link to={`/account/favorites/${userFavourite.id}`}>
          <ArrowButton imgHeight="2.3rem" name={`SEE ALL`} />
        </Link>
      </div>
      <div className={styles.modals}>
        <Modal isOpen={isShareModalOpen} handleClose={toggleShareModal}>
          <ShareModal link={''} favoritesShare={LINK} />
        </Modal>
      </div>
    </div>
  );
};

export default FavoriteList;
