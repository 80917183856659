import React from 'react';
import styles from './MobileFiltersBtn.module.scss';
import filterIconBlack from '../../../../images/filters-icon-black.svg';
import filterIconWhite from '../../../../images/filters-icon-white.svg';

interface Props {
  name: string;
  isDark?: boolean;
  onClick: () => void;
}

const MobileFiltersBtn: React.FC<Props> = ({ onClick, name, isDark }: Props) => {
  return (
    <div className={styles.mobileFilterBtn + (isDark ? ` ${styles.dark}` : '')} onClick={onClick}>
      <div className={styles.text}>
        <img src={isDark ? filterIconWhite : filterIconBlack} alt="filter button black" />
        <p>{name}</p>
      </div>
    </div>
  );
};

export default MobileFiltersBtn;
