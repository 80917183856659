import { RouteComponentProps } from '@reach/router';
import React from 'react';
import AccountLayout from '../layout/account-layout';
import AccountDetails from './account-details/AccountDetails';

type Props = RouteComponentProps;

const AccountDetail: React.FC<Props> = () => {
  if (typeof window === 'undefined') {
    return <div></div>;
  }

  return (
    <AccountLayout>
      <AccountDetails />
    </AccountLayout>
  );
};

export default AccountDetail;
