import { Router } from '@reach/router';
import { navigate } from 'gatsby';
import React, { useEffect } from 'react';
import AccountDetail from '../../components/account/account-details';
import AccountFavorites from '../../components/account/account-favorites';
import useAuth from '../../hooks/useAuth';
import FavoriteDetailsPage from '../../components/account/account-favorites-details-template';
import useFavourite from '../../hooks/useFavourite';
import { AccountPageContext } from './interfaces';
import { formatAccountData } from './accont.util';

interface Props {
  location: Location;
  pageContext: AccountPageContext;
}

const AccountTemplate: React.FC<Props> = ({ pageContext }: Props) => {
  const { isAuthenticated } = useAuth();
  const favouritesAPI = useFavourite();
  const { userFavourites, fetchFavourites } = favouritesAPI;

  useEffect(() => {
    fetchFavourites();
  }, []);

  useEffect(() => {
    if (!isAuthenticated) {
      navigate('/?login=true');
    }
  }, [isAuthenticated]);

  if (typeof window === 'undefined') {
    return <div></div>;
  }

  const formattedData = formatAccountData(pageContext, userFavourites);

  return (
    <Router basepath="/account">
      <AccountDetail default={true} path="/details" />

      <FavoriteDetailsPage
        favouriteAPI={favouritesAPI}
        path="/favorites/:id"
        userFavourites={formattedData.favourites}
      />

      <AccountFavorites path="/favorites" userFavourites={formattedData.favourites} />
    </Router>
  );
};

export default AccountTemplate;
