import React from 'react';
import AccountLayout from '../layout/account-layout';
import FavoriteDetails from './favorite-details/FavoriteDetails';
import { RouteComponentProps } from '@reach/router';
import { UseFavourite } from '../../hooks/useFavourite';
import { FormattedUserFavourite } from '../../templates/account/interfaces';

interface Props {
  id: string;
  userFavourites: FormattedUserFavourite[];
  favouriteAPI: UseFavourite;
  location: Location;
}

const FavoriteDetailsPage: React.FC<RouteComponentProps<Props>> = ({
  id,
  userFavourites,
  favouriteAPI,
  location,
}: RouteComponentProps<Props>) => {
  if (!id || !userFavourites || !userFavourites?.length) {
    return <AccountLayout />;
  }

  const favourite: FormattedUserFavourite | undefined = userFavourites.find((f) => f.id === +id);

  if (!favourite) {
    return <AccountLayout />;
  }

  return (
    <AccountLayout>
      <FavoriteDetails favouriteAPI={favouriteAPI!} favourite={favourite} location={location} />
    </AccountLayout>
  );
};

export default FavoriteDetailsPage;
